import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { PublishedCourse } from '../model/publishedCourse';
import PrivatePage from '../layouts/PrivatePage';
import styled from 'styled-components';
import SideDrawer, { SideDrawerProps } from '../components/courses/SideDrawer';
import VideoPlayer from '../components/courses/VideoPlayer';
import { Text } from 'rebass/styled-components';
import NodeRichText from '../model/NodeRichText';
import Surface from '../components/Surface';
import richTextRenderer from '../components/RichTextRenderer';
import colors from '../../colors';
import FAIcon from '../components/FAIcon';
import SecondaryRaisedButton from '../components/SecondaryRaisedButton';
import BackButton from '../components/BackButton';

interface CourseModule {
  readonly title: string;
  readonly note: NodeRichText;
  readonly videoUrl: string;
  readonly slidesUrl?: string;
  readonly subtitlesUrl?: string;
}

interface Course extends PublishedCourse {
  readonly modules: CourseModule[];
}

const Container = styled.div<{ readonly isExpanded: boolean }>`
  display: block;
  @media screen and (min-width: 768px) {
    display: flex;
  }
  width: 100%;
  height: 100%;
`;

const StyledDrawer: React.FC<SideDrawerProps> = styled(SideDrawer)`
  @media screen and (min-width: 768px) {
    width: 24rem;
    height: 100%;
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
  height: ${(props) => (props.isExpanded ? '100%' : '0')};
  width: ${(props) => (props.isExpanded ? '100%' : '0')};
  transition: height 0.3s ease-in 0s;
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  position: absolute;
  z-index: 1;
`;

const StyledBackButton = styled(BackButton)`
  margin-top: 16px;
  margin-left: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  width: 150px;
`;

const DetailsContainer = styled.div<{ readonly isExpanded: boolean }>`
  display: ${(props) => (props.isExpanded ? 'none' : 'flex')};
  flex-direction: column;
  padding: 0 20px;
  margin: 16px 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 768px) {
    padding: 0 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 12px;
  transition: all 0.3s ease-in 0s;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const VideoContainer = styled(Surface)`
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding: 40px;
  }
`;

const CourseItem: React.FC<{
  readonly data: {
    readonly allContentfulCourse: { readonly nodes: Course[] };
  };
}> = ({ data }) => {
  const course = data.allContentfulCourse.nodes[0];
  const [isModulesExpanded, setModulesExpanded] = useState(false);
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
  return (
    <PrivatePage
      title={course.title}
      description={course.description.description}
      featureImageUrl={getImage(course.image)!.images.sources![0].srcSet}
    >
      <StyledBackButton to="/portal/" title="Back to Portal" />
      <ButtonContainer>
        <SecondaryRaisedButton
          disabled={selectedModuleIndex === 0}
          onClick={() => {
            setSelectedModuleIndex(selectedModuleIndex - 1);
          }}
        >
          <FAIcon name={'arrow-left'} color={colors.background} />
        </SecondaryRaisedButton>

        <SecondaryRaisedButton
          onClick={() => setModulesExpanded(!isModulesExpanded)}
        >
          Show all
        </SecondaryRaisedButton>

        <SecondaryRaisedButton
          disabled={selectedModuleIndex === course.modules.length - 1}
          onClick={() => {
            setSelectedModuleIndex(selectedModuleIndex + 1);
          }}
        >
          <FAIcon name={'arrow-right'} color={colors.background} />
        </SecondaryRaisedButton>
      </ButtonContainer>
      <Container isExpanded={isModulesExpanded}>
        <StyledDrawer
          selectedItem={course.modules[selectedModuleIndex].title}
          onItemSelected={(selected) => {
            setSelectedModuleIndex(
              course.modules.findIndex((item) => item.title === selected),
            );
            setModulesExpanded(false);
          }}
          isExpanded={isModulesExpanded}
          items={course.modules.map((item) => item.title)}
        />
        <DetailsContainer isExpanded={isModulesExpanded}>
          <VideoContainer>
            <Text maxWidth={['100%', '75%']} fontSize={[3, 4]} fontWeight={800}>
              {course.modules[selectedModuleIndex].title}
            </Text>
            <div style={{ height: '20px' }} />
            <VideoPlayer
              originalVideoUrl={course.modules[selectedModuleIndex].videoUrl}
              originalSubtitlesUrl={
                course.modules[selectedModuleIndex].subtitlesUrl
              }
            />
          </VideoContainer>
          <div style={{ height: '40px' }} />
          <Surface style={{ flexGrow: 1 }}>
            {course.modules[selectedModuleIndex].slidesUrl && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <SecondaryRaisedButton
                  onClick={() =>
                    window.open(
                      course.modules[selectedModuleIndex].slidesUrl,
                      '_blank',
                    )
                  }
                >
                  Download Slides
                </SecondaryRaisedButton>
              </div>
            )}
            {richTextRenderer(course.modules[selectedModuleIndex].note.raw)}
          </Surface>
        </DetailsContainer>
      </Container>
    </PrivatePage>
  );
};

export const courseQuery = graphql`
  query SingleCourse($slug: String!) {
    allContentfulCourse(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        sku
        slug
        description {
          description
        }
        image {
          gatsbyImageData(
            height: 250
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        modules {
          title
          videoUrl
          slidesUrl
          subtitlesUrl
          note {
            raw
          }
        }
      }
    }
  }
`;

export default CourseItem;
