import React from 'react';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

export interface SideDrawerProps {
  readonly items: string[];
  readonly isExpanded: boolean;
  readonly className?: string;
  readonly onItemSelected: (selected: string) => void;
  readonly selectedItem: string;
}

const ListContainer = styled.ul`
  @media screen and (min-width: 768px) {
    padding: 20px;
  }
  padding: 0;
  list-style-type: none;
  background-color: ${(props) => props.theme.colors.background};
`;

const Item = styled.li<{
  readonly isExpanded: boolean;
  readonly isSelected: boolean;
}>`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 12px;
  transition: all 0.3s ease-in 0s;
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  user-select: none;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primaryLight : undefined};
  border-bottom: 1px solid #3052575a;
  &:hover {
    cursor: ${(props) => (props.isExpanded ? 'pointer' : undefined)};
    background-color: ${(props) =>
      props.isSelected
        ? props.theme.colors.primaryLight
        : 'rgba(254, 239, 234, 0.5)'};
  }

  @media screen and (min-width: 768px) {
    opacity: 1;
  }
`;

const SideDrawer: React.FC<SideDrawerProps> = ({
  items,
  className,
  isExpanded,
  selectedItem,
  onItemSelected,
}) => {
  return (
    <ListContainer className={className}>
      {items.map((item, index) => (
        <Item
          onClick={() => onItemSelected(item)}
          isSelected={item === selectedItem}
          isExpanded={isExpanded}
          key={`${item}-${index}`}
        >
          <Text>{item}</Text>
        </Item>
      ))}
    </ListContainer>
  );
};

export default SideDrawer;
