import styled from 'styled-components';
import { Card } from 'rebass/styled-components';

const Surface = styled(Card).attrs({
  bg: 'white',
})`
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
`;

export default Surface;
