import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import firebaseProvider from '../../services/FirebaseProvider';
import { captureException } from '@sentry/gatsby';

interface Props {
  readonly originalVideoUrl: string;
  readonly originalSubtitlesUrl?: string;
}

const VideoPlayer: React.FC<Props> = ({
  originalVideoUrl,
  originalSubtitlesUrl,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    const videoElement = videoRef.current;
    if (!videoElement) return;
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      aspectRatio: '16:9',
      fluid: true,
      html5: {
        nativeTextTracks: false,
      },
    };
    const player = (playerRef.current = videojs(
      videoElement,
      videoJsOptions,
      () => {
        playerRef.current = player;
      },
    ));
    player.on('error', () => {
      const error = player?.error();
      if (error) {
        captureException(error);
      }
    });
  }, [videoRef]);

  useEffect(() => {
    firebaseProvider
      .getAuth()
      .then((auth) => auth.currentUser?.getIdToken())
      .then((token) => {
        const videoUrl = originalVideoUrl.replace(
          `${process.env.GATSBY_API_URL}videos/`,
          `${process.env.GATSBY_API_URL}videos/${token}/`,
        );
        const subtitlesUrl = originalSubtitlesUrl?.replace(
          `${process.env.GATSBY_API_URL}videos/`,
          `${process.env.GATSBY_API_URL}videos/${token}/`,
        );
        // you can update player here [update player through props]
        const player = playerRef.current;

        if (player) {
          player.src([
            {
              src: videoUrl,
              type: 'application/x-mpegURL',
            },
          ]);
          const tracks = player.textTracks();
          for (let idx = 0; idx < tracks.length; idx++) {
            player.removeRemoteTextTrack(tracks[idx]);
          }
          player.addRemoteTextTrack(
            {
              kind: 'captions',
              src: subtitlesUrl,
              srcLang: 'en',
              label: 'English',
            },
            true,
          );
          const settings = player.textTrackSettings;
          settings.setValues({
            backgroundColor: '#000',
            backgroundOpacity: '0.5',
            edgeStyle: 'none',
          });
          settings.updateDisplay();
        }
      });
  }, [originalVideoUrl, originalSubtitlesUrl, playerRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
};

export default VideoPlayer;
