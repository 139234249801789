import React from 'react';
import { Link } from 'gatsby';
import FAIcon from './FAIcon';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

interface BackButtonProps {
  readonly to: string;
  readonly title: string;
  readonly className?: string;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 5px;
  transition: background 300ms;
  border-radius: 6px;
  background: #ffffff;
  :hover {
    background: #f5f5f5;
  }
`;

const BackButton: React.FC<BackButtonProps> = ({ ...props }) => {
  return (
    <StyledLink to={props.to} className={props.className}>
      <FAIcon name="chevron-left" />
      <Text
        textAlign="left"
        marginLeft="10px"
        as="span"
        color="textColor"
        fontSize={[2, 2, 2]}
      >
        {props.title}
      </Text>
    </StyledLink>
  );
};

export default BackButton;
